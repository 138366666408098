<template>
  <v-row>
    <v-col cols="12">
      <div class="font-weight-bold text-body-2 d-flex align-center mt-2">
        Перегрузка №{{ index + 1 }}
        <v-btn
          max-width="36px"
          min-width="36px"
          color="transparent"
          class="elevation-0 ml-1"
          @click="deleteReload"
        >
          <v-icon
            tabindex="-1"
            color="grey darken-1"
            small
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </div>
      <v-divider class="mt-2" />
    </v-col>
    <v-row
      :id="block_id"
      v-click-outside="triggerOnFocus"
      class="px-2"
    >
      <v-col cols="2">
        <label for="">
          Страна
          <v-autocomplete
            ref="country_letter"
            v-model="reload_item.country_letter"
            auto-select-first
            outlined
            dense
            hide-details="auto"
            background-color="white"
            :items="countries"
            item-text="text"
            item-value="letterCode"
            :filter="filterBySearchField"
          />
        </label>
      </v-col>
      <v-col
        v-if="!isOldStructure"
        cols="3"
      >
        <label for="">
          Наименование пункта перегрузки
          <v-text-field
            ref="place"
            v-model="reload_item.place"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
      <v-col
        v-if="!isOldStructure"
        cols="2"
      >
        <label for="">
          Код пункта перегрузки
          <v-autocomplete
            ref="customs_code"
            v-model="reload_item.customs_code"
            :items="customsPoints"
            item-text="text"
            item-value="code"
            auto-select-first
            outlined
            dense
            hide-details="auto"
            background-color="white"
            class="mr-1"
            :filter="filterBySearchField"
          />
        </label>
      </v-col>
      <v-col
        v-if="!isOldStructure"
        cols="2"
      >
        <label for="">
          № перевозчика
          <v-text-field
            ref="place"
            v-model="reload_item.carrier_ordinal"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
      <v-col cols="1">
        <label for="">
          Контейнер
          <v-autocomplete
            ref="container_indicator"
            v-model="reload_item.container_indicator"
            item-text="text"
            item-value="value"
            :items="container_types"
            auto-select-first
            background-color="white"
            dense
            outlined
            hide-details="true"
          />
        </label>
      </v-col>
      <v-col
        :offset="isOldStructure ? 2 : 0"
        :cols="isOldStructure ? 7 : 2"
      >
        <label>
          Грузовая операция
          <v-autocomplete
            ref="reload_type"
            v-model="reload_item.reload_type"
            item-text="text"
            item-value="value"
            :items="reload_types"
            auto-select-first
            background-color="white"
            dense
            outlined
            hide-details="true"
          />
        </label>
      </v-col>
      <v-col
        v-if="isOldStructure"
        cols="5"
      >
        <label>
          Код таможенного пункта пергрузки
          <v-autocomplete
            ref="destination_customs_code"
            v-model="reload_item.customs_code"
            auto-select-first
            outlined
            dense
            hide-details="auto"
            background-color="white"
            class="mr-1"
            item-text="text"
            item-value="code"
            :items="customsPoints"
            :filter="filterBySearchField"
          />
        </label>
      </v-col>
      <v-col
        v-if="isOldStructure"
        cols="3"
      >
        <label for="">
          Наименование пункта перегрузки
          <v-text-field
            ref="place"
            v-model="reload_item.place"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
      <v-col
        v-if="isOldStructure"
        cols="1"
      >
        <label for="">
          Страна
          <v-autocomplete
            ref="transport_country_letter"
            v-model="reload_item.transport_country_letter"
            auto-select-first
            outlined
            dense
            hide-details="auto"
            background-color="white"
            :items="countries"
            item-text="text"
            item-value="letterCode"
            :filter="filterBySearchField"
          />
        </label>
      </v-col>
      <v-col
        v-if="isOldStructure"
        cols="3"
      >
        <label for="">
          Вид транспорта
          <v-autocomplete
            ref="vehicle_type_code"
            v-model="reload_item.vehicle_type_code"
            auto-select-first
            outlined
            dense
            hide-details="auto"
            background-color="white"
            :items="transportTypes"
            item-text="text"
            item-value="code"
            :filter="filterBySearchField"
          />
        </label>
      </v-col>
    </v-row>
    <v-col
      v-click-outside="triggerOnFocus"
      class="prevent-trigger-update"
      :cols="isOldStructure ? '10' : '12'"
    >
      <v-btn
        v-show="!transports.length"
        text
        class="px-1"
        @click="createReloadTransport"
      >
        <v-icon>mdi-plus</v-icon>
        Добавить Транспорт
      </v-btn>
      <v-simple-table
        v-show="transports.length"
        class="bg-gray"
        fixed-header
        dense
      >
        <template #default>
          <thead>
            <tr>
              <th v-if="!isOldStructure">
                № п/п
              </th>
              <th v-if="!isOldStructure">
                Код вида
              </th>
              <th>Номер ТС</th>
              <th v-if="!isOldStructure">
                Номер шасси (VIN)
              </th>
              <th>Страна рег.</th>
              <th>{{ isOldStructure ? "Код вида ТС" : "Код типа ТС" }}</th>
              <th v-if="!isOldStructure">
                Код марки ТС
              </th>
              <th v-if="!isOldStructure">
                Модель
              </th>
              <th v-if="!isOldStructure">
                Номер документа
              </th>
              <th v-if="!isOldStructure">
                Ссылочный № ТС из 18
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            <transport-item
              v-for="(transport, idx) in transports"
              :key="idx"
              :item="transport"
              :idx="idx"
              :reload-item="reload_item"
              :reload-item-index="index"
              :control="control"
              @create="createReloadTransport"
              @delete="deleteReloadTransport"
              @update="updateReloadTransport"
              @ready="readyToUpdate"
            />
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    <v-col
      v-if="isOldStructure"
      cols="2"
    >
      <label>Контейнеры</label>
      <container-item
        v-for="(container, idx) in containers_json"
        :key="`reload-${index}-container-${idx}`"
        :container-indicator="reload_item.container_indicator"
        :idx="idx"
        :item="container"
        @add="addContainerItem"
        @delete="deleteContainerItem"
        @update="updateContainerItem"
      />
      <v-btn
        v-if="!containers_json.length"
        class="prevent-trigger-update px-1"
        :disabled="!reload_item.container_indicator"
        text
        @click="addContainerItem"
      >
        <v-icon>mdi-plus</v-icon>
        Добавить контейнер
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import TransportItem from "@/components/epi/form/reloads/transport-item.vue";
import ContainerItem from "@/components/epi/form/reloads/container-item";
import {getCountryNameByCode, getCustomsNameByCode, getTransportCodeByName} from "@/helpers/catalogs";
import { convertEmptyStringsToNull } from "@/helpers/objects";
import highlightFieldsChild from "@/mixins/control/highlight-fields-child";
import {mapGetters} from "vuex";
import cloneDeep from "lodash.clonedeep";

export default {
  components: { TransportItem, ContainerItem },
  mixins: [filterBySearchFieldMixin, blockAutoUpdate, highlightFieldsChild],
  props: {
    item: {
      required: true,
      type: Object,
    },
    index: {
      required: true,
      type: Number,
    },
  },
  data:()=>({
    block_id: null,
    stop_propagation:true, // stop propagation
    reload_types:[
      {
        text:"1 - разгрузка в месте прибытия",
        value:1
      },
      {
        text:"2 - перегрузка (перевалка), замена ТС в месте прибытия",
        value:2
      },
      {
        text:"3 - перегрузка (перевалка), замена ТС при перевозке в соответствии с таможенной процедурой таможенного транзита",
        value:3
      },
    ],
    reload_item: {
      id: "",
      declaration_id: "",
      country_letter: "",
      country_name: "",
      container_indicator: "",
      place: "",
      reload_type:"",
      customs_code:"",
      vehicle_type_code:"",
      transport_country_letter:"",
      transport_country_name:"",
    },
    transports: [],
    containers_json: [],
    container_types: [
      {
        text: 0,
        value: false,
      },
      {
        text: 1,
        value: true,
      },
    ],
  }),
  computed:{
    ...mapGetters({
      selected: "epi/getSelected",
      countries: "catalogs/getNsiCountries",
      customsPoints: "catalogs/getNsiCustomsPoints",
      transportTypes:"catalogs/getNsiTransportTypes",
      user: "auth/getUser",
      nsiTransportMarks: "epi/getNsiTransportMarks",
      isOldStructure: "epi/getIsOldStructure",
    }),
    divisionId() {
      return this.user?.user?.activeDivisionId;
    }
  },
  watch: {
    item: {
      handler() {
        this.block_id = `epi-reload-item-${this.item.id}`;
        this.setFields()
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    setFields(){
      Object.keys(this.item).forEach(key => {
        if(key in this.reload_item){
          this.reload_item[key] = this.item[key]
        }
      })
      this.containers_json = cloneDeep(this.item.containers_json ?? [])
      this.transports = cloneDeep(this.item.transports ?? [])
    },
    updateContainerItem({container, index}){
      this.containers_json[index] = container
      this.readyToUpdate()
    },
    deleteContainerItem(idx) {
      this.containers_json.splice(idx, 1);
      this.readyToUpdate()
    },
    addContainerItem() {
      this.containers_json.push({ number: null });
      this.readyToUpdate()
    },
    createReloadTransport(){
      this.$store.dispatch('epi/createReloadTransport', this.reload_item.id)
        .then((res) => {
          this.transports.push(res.data)
          this.readyToUpdate()
        })
    },
    deleteReloadTransport({id, index}){
      const data = {
        id: this.reload_item.id,
        transport_id:id
      }
      this.$store.dispatch('epi/deleteReloadTransport', data).then(() => {
        this.transports.splice(index, 1)
        this.readyToUpdate()
      })
    },
    updateReloadTransport({item, index}){
      this.transports.splice(index, 1, item)
    },
    readyToUpdate(){
      this.hasChanges = true
      this.fields_been_visited = true
    },
    deleteReload(){
      this.$store.dispatch("epi/deleteReload", this.item.id);
    },
    saveToCatalogMultipleVehicles() {
      this.transports.forEach(transport => {
        const country_name = getCountryNameByCode(this.countries, transport.country_letter)
        const transport_mark_code = getTransportCodeByName(this.nsiTransportMarks, transport.transport_mark_name)
        const payload = {
          id: null,
          number: transport.number,
          country_letter: transport.country_letter,
          vehicle_type: transport.vehicle_type,
          transport_mark_name: transport.transport_mark_name,
          doc_number: null,
          country_name,
          transport_mark_code,
          division_id: this.divisionId,
        }
        this.$store.dispatch("catalogs/saveVehicle", payload)
      })
    },
    uploadData() {
      const index = this.index;
      const transports = this.transports.map(transport => {
        return {
          ...transport,
          country_name: getCountryNameByCode(this.countries, transport.country_letter),
        }
      })
      const containers_json = this.containers_json
      const payload = convertEmptyStringsToNull({
        ...this.reload_item,
        containers_json,
        transports,
        customs_name:getCustomsNameByCode(this.customsPoints, this.reload_item.customs_code),
        country_name: getCountryNameByCode(
          this.countries,
          this.reload_item.country_letter
        ),
        transport_country_name:getCountryNameByCode(
          this.countries,
          this.reload_item.transport_country_letter
        ),
      });

      return this.$store.dispatch("epi/updateReload", {
        index,
        payload,
      }).then(res => {
        this.saveToCatalogMultipleVehicles()
        return res
      });
    },
  },
};
</script>

<style scoped>
.bg-gray {
  background-color: #ededed !important;
}
thead tr th {
  padding-left: 0 !important;
  background-color: #ededed !important;
  box-shadow: none !important;
}
td {
  padding-left: 0 !important;
  border-bottom: none !important;
  padding-bottom: 10px !important;
}
</style>
