<template>
  <v-container fluid>
    <v-row :id="block_id">
      <v-col cols="12">
        <div
          id="epi-carriers"
          class="statistics-box statistics-carriers-list pa-4"
          :class="{'blocked-box' : blocked_visibility}"
        >
          <v-row>
            <v-col cols="12">
              <div class="font-weight-bold">
                Перевозчик по ЕАЭС
              </div>
              <v-col
                cols="12"
                class="py-0"
              >
                <carrier-item
                  v-for="(item, index) in carriers"
                  v-show="carriers.length"
                  :key="index"
                  :item="item"
                  :index="index"
                  :control="controlPath"
                />
              </v-col>
              <v-row>
                <v-col cols="12">
                  <v-btn
                    text
                    class="px-0 mt-2"
                    @click="createCarrier"
                  >
                    <v-icon>mdi-plus</v-icon>
                    Добавить перевозчика
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import CarrierItem from "@/components/epi/form/carriers/carrier-item";
import { carriers as onСarriers } from "@/events/statistics/control";
import { getScrollOffset } from "@/helpers/control";
import blockVisibility from '@/mixins/block-visibility'

export default {
  components: { CarrierItem },
  mixins: [filterBySearchFieldMixin, blockVisibility],
  data: () => ({
    block_id: "epi-carrier-eeu",
    carriers: [],
    controlPath: {
      path: null
    },
  }),
  computed: {
    ...mapGetters({
      selected: "epi/getSelected",
    }),
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    onСarriers.subscribe(this.onControl);
  },
  beforeDestroy() {
    onСarriers.unsubscribe();
  },
  onControl(data) {
    const docs = document.querySelector(".statistics-carriers-list");
    const offset = getScrollOffset();
    this.$scrollTo(docs, 200, {
      force: true,
      offset,
      onDone: () => this.highlight(data),
    });
  },
  highlight({ path }) {
    this.controlPath = {path}
  },
  methods: {
    createCarrier() {
      const sortIndex = this.carriers.length+1;
      this.$store.dispatch("epi/createCarrier", sortIndex);
    },
    setFields() {
      this.carriers = this.selected.carriers;
    },
  },
};
</script>
