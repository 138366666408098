<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div
          id="epi-reloads"
          class="statistics-box statistics-reloads-list pa-4"
          :class="{'blocked-box' : disabledView}"
        >
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col
                  cols="9"
                  class="pb-0"
                >
                  <div class="font-weight-bold d-flex align-center mt-2">
                    {{ title }}
                  </div>
                </v-col>
              </v-row>
              <reload-item
                v-for="(item, index) in reloads"
                v-show="reloads.length"
                :key="index"
                :item="item"
                :index="index"
                :control="controlPath"
              />
              <v-row>
                <v-col cols="12">
                  <v-btn
                    text
                    class="px-0"
                    @click="createReload"
                  >
                    <v-icon>mdi-plus</v-icon>
                    Добавить перегрузку
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import ReloadItem from "@/components/epi/form/reloads/reload-item";
import { reloads as onReloads } from "@/events/statistics/control";
import { getScrollOffset } from "@/helpers/control";

export default {
  components: { ReloadItem },
  mixins: [filterBySearchFieldMixin],
  data: () => ({
    reloads: [],
    controlPath: {
      path: null
    },
  }),
  computed: {
    ...mapGetters({
      selected: "epi/getSelected",
      disabledView:"epi/getVisibility",
      isOldStructure: "epi/getIsOldStructure",
    }),
    title() {
      return this.isOldStructure ? "Грузовые операции / Перегрузки" : "55 Перегрузки";
    },
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    onReloads.subscribe(this.onControl);
  },
  beforeDestroy() {
    onReloads.unsubscribe();
  },
  onControl(data) {
    const docs = document.querySelector(".statistics-reloads-list");
    const offset = getScrollOffset();
    this.$scrollTo(docs, 200, {
      force: true,
      offset,
      onDone: () => this.highlight(data),
    });
  },
  highlight({ path }) {
    this.controlPath = {path}
  },
  methods: {
    createReload() {
      this.$store.dispatch("epi/createReload", this.selected.id);
    },
    setFields() {
      this.reloads = this.selected.reloads;
    },
  },
};
</script>
