<template>
  <v-row
    class="d-flex align-end justify-space-between mb-2"
    no-gutters
  >
    <v-col cols="2">
      <label v-if="idx===0">Код вида</label>
      <v-autocomplete
        ref="kind_code"
        v-model="identification_item.kind_code"
        item-text="text"
        item-value="code"
        :items="typeCodeMock"
        auto-select-first
        background-color="white"
        dense
        outlined
        hide-details="true"
        :filter="filterByCode"
        @change="updateFields"
      />
    </v-col>
    <v-col cols="2">
      <label v-if="idx===0">Количество</label>
      <v-text-field
        v-model="identification_item.quantity"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        maxlength="4"
        @change="updateFields"
      />
    </v-col>

    <v-tooltip
      bottom
      max-width="300px"
    >
      <template #activator="{ on }">
        <v-col cols="5">
          <label v-if="idx===0">Номера</label>
          <v-text-field
            v-model="identification_item.numbers"
            outlined
            dense
            hide-details="auto"
            background-color="white"
            @change="updateFields"
            v-on="on"
          />
        </v-col>
      </template>
      <span class="font-weight-black">Внимание!</span>
      При указании нескольких средств идентификации, перечислять через "," 
    </v-tooltip>

    <v-col cols="2">
      <v-btn
        class="document-add elevation-0"
        color="transparent"
        min-width="36px"
        max-width="36px"
        @click="deleteContainerItem"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        class="document-add elevation-0"
        color="transparent"
        min-width="36px"
        max-width="36px"
        @click="addContainerItem"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    item: {
      required: true,
      type: Object
    },
    idx: {
      required: true,
      type: Number
    },
  },
  data: () => ({
    identification_item: {
      kind_code: null,
      quantity: null,
      numbers: null,
    },
  }),
  computed: {
    ...mapGetters({
      typeCodeMock: "catalogs/getNsiTypeCodeMock",
      isOldStructure: "epi/getIsOldStructure",
    }),
  },
  watch:{
    item:{
      handler(newVal) {
        Object.keys(newVal).forEach(key => {
          if(key in this.identification_item){
            this.identification_item[key] = newVal[key]
          }
        })
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    deleteContainerItem() {
      this.$emit('delete', this.idx)
    },
    addContainerItem() {
      this.$emit('add')
    },
    updateFields() {
      this.$emit('update', {container: this.identification_item, index: this.idx})
    },
    filterByCode(item, queryText) {
      return (
        item?.kind_code.indexOf(queryText.toLocaleLowerCase()) >
          -1
      );
    },
  }
}
</script>