<template>
  <tr
    :id="block_id"
    v-click-outside="triggerOnFocus"
  >
    <td
      v-if="!isOldStructure"
      style="width: 4%"
    >
      <v-text-field
        ref="number"
        :value="transport_item.sort_index"
        class="mr-1"
        outlined
        dense
        disabled
        hide-details="auto"
        background-color="grey lighten-2"
      />
    </td>
    <td
      v-if="!isOldStructure"
      style="width: 8%"
    >
      <v-autocomplete
        ref="border_transport_type"
        v-model="transport_item.border_transport_type"
        item-text="text"
        item-value="code"
        :items="transports"
        auto-select-first
        background-color="white"
        dense
        outlined
        hide-details="true"
        :filter="filterByCode"
      />
    </td>
    <td style="width: 8%">
      <v-text-field
        ref="number"
        :value="transport_item.number"
        class="mr-1 pr-0"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        @input="(v) => transport_item.number = v.replace(' ', '')"
        @keypress.enter="showVehiclesCatalog"
      />
    </td>
    <td style="width: 11%">
      <v-text-field
        ref="vin"
        v-model="transport_item.vin"
        class="mr-1"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </td>
    <td style="width: 5%">
      <v-autocomplete
        ref="country_letter"
        v-model="transport_item.country_letter"
        auto-select-first
        outlined
        dense
        hide-details="auto"
        background-color="white"
        :items="countries"
        item-text="text"
        item-value="letterCode"
        :filter="filterBySearchField"
      />
    </td>
    <td style="width: 6%">
      <v-autocomplete
        ref="vehicle_type"
        v-model="transport_item.vehicle_type"
        auto-select-first
        background-color="white"
        dense
        outlined
        hide-details="true"
        item-value="code"
        item-text="text"
        :items="vehicleTypes"
        :filter="filterBySearchField"
      />
    </td>
    <td style="width: 8%">
      <v-autocomplete
        ref="transport_mark_code"
        v-model="transport_item.transport_mark_code"
        item-text="text"
        item-value="code"
        :items="nsiTransportMarks"
        auto-select-first
        background-color="white"
        dense
        outlined
        hide-details="true"
        :filter="filterBySearchField"
      />
    </td>
    <td style="width: 6%">
      <v-text-field
        ref="model_name"
        v-model="transport_item.model_name"
        background-color="white"
        dense
        outlined
        hide-details="true"
      />
    </td>
    <td style="width: 8%">
      <v-text-field
        ref="doc_number"
        v-model="transport_item.doc_number"
        class="mr-1"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </td>
    <td style="width: 3%">
      <div ref="graph_18">
        <v-checkbox
          v-model="transport_item.graph_18"
          color="#333"
          hide-details
          dense
          @change="updateGraphs"
        />
      </div>
    </td>
    <td style="width: 3%">
      <div ref="graph_21">
        <v-checkbox
          v-model="transport_item.graph_21"
          color="#333"
          hide-details
          dense
          @change="updateGraphs"
        />
      </div>
    </td>
    <td style="width: 6%">
      <v-btn
        max-width="36px"
        min-width="36px"
        color="transparent"
        class="elevation-0"
        @click="deleteTransport"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        v-if="index === itemsAmount - 1"
        class="document-add elevation-0"
        color="transparent"
        min-width="36px"
        max-width="36px"
        @click="addNewTransport"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import {convertEmptyStringsToNull} from "@/helpers/objects";
import {getCountryNameByCode, getTransportCodeByName, getTransportMarkNameByCode} from "@/helpers/catalogs";
import {catalogValueSelected, showCatalog} from "@/events/statistics";
import {mapGetters} from "vuex";
import {highlightTransportItemField} from "@/helpers/control";

export default {
  // highlightFieldsChild - watch переопределен локально в компоненте !
  mixins: [filterBySearchFieldMixin, blockAutoUpdate],
  props: {
    control: {
      required: true,
      type: Object
    },
    item: {
      required: true,
      type: Object,
    },
    countries: {
      required: true,
      type: Array,
    },
    transport: {
      required: true,
      type: Array,
    },
    nsiTransportMarks: {
      required: true,
      type: Array,
    },
    itemsAmount: {
      required: true,
      type: Number,
    },
    vehicleTypes: {
      required: true,
      type: Array,
    },
    index: {
      required: true,
      type: Number,
    },
  },
  data: () => ({
    block_id: null,
    certificate_info: {
      certificate_number: null,
      certificate_date: null,
    },
    transport_item: {
      id: "",
      declaration_id: "",
      number: "",
      country_letter: "",
      country_name: "",
      vehicle_type: "",
      transport_mark_code: "",
      transport_mark_name: "",
      vin: "",
      doc_number: "",
      graph_18: "",
      graph_21: "",
      model_name: "",
      border_transport_type: "",
      sort_index: "",
    },
  }),
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
      selected: "epi/getSelected",
      shipmentIndex: "epi/getShipmentIndex",
      transports: "catalogs/getNsiTransportTypes",
      isOldStructure: "epi/getIsOldStructure",
    }),
    divisionId() {
      return this.user?.user?.activeDivisionId;
    }
  },
  watch: {
    item: {
      handler(newVal) {
        this.block_id = `epi-transport-item-${this.item.id}`;
        for (let key in newVal) {
          if (key in this.transport_item) {
            this.transport_item[key] = newVal[key];
          }
        }
      },
      immediate: true,
      deep: true,
    },
    control: {
      handler({path}) {
        highlightTransportItemField.call(this, path)
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    catalogValueSelected.subscribe(this.updatesResolver, [
      'transport/number',
    ])
  },
  methods: {
    saveToCatalog() {
      const country_name = getCountryNameByCode(this.countries, this.transport_item.country_letter)
      const transport_mark_code = getTransportCodeByName(this.nsiTransportMarks, this.transport_item.transport_mark_name)
      const payload = {
        id: null,
        division_id: this.divisionId,
        number: this.transport_item.number,
        country_letter: this.transport_item.country_letter,
        vehicle_type: this.transport_item.vehicle_type,
        transport_mark_name: this.transport_item.transport_mark_name,
        vin: this.transport_item.vin,
        doc_number: this.transport_item.doc_number,
        border_transport_type: this.transport_item.border_transport_type,
        model_name: this.transport_item.model_name,
        country_name,
        transport_mark_code
      }
      this.$store.dispatch("catalogs/saveVehicle", payload)
    },
    updatesResolver({id, field, code, item}) {
      if (id === this.transport_item.id && code && field) {
        if (field === 'transport/number') {
          this.updateTransport(item)
        } else {
          const [, key] = field.split('/')
          this.transport_item[key] = code
        }
        this.readyToUpdate()

      }
    },
    updateTransport(transport) {
      const {
        number, model_name, country_letter,
        vehicle_type, transport_mark_code, transport_mark_name, vin, doc_number,
        certificate_number, certificate_date
      } = transport
      this.transport_item = {
        ...this.transport_item,
        number,
        country_letter,
        vehicle_type,
        transport_mark_code,
        transport_mark_name,
        model_name,
        vin,
        doc_number
      }
      this.certificate_info = {certificate_number, certificate_date}
    },
    showVehiclesCatalog() {
      showCatalog.trigger({
        type: "vehicles",
        id: this.transport_item.id,
        search: this.transport_item.number,
        field: "transport/number"
      });
    },
    updateGraphs() {
      this.hasChanges = true;
      this.fields_been_visited = true;
    },
    addNewTransport() {
      this.$emit("addNewTransport");
    },
    deleteTransport() {
      this.$emit("deleteTransport", this.transport_item.id);
    },
    readyToUpdate() {
      this.hasChanges = true
      this.fields_been_visited = true
    },
    filterByCode(item, queryText) {
      return (
        item?.code.indexOf(queryText.toLocaleLowerCase()) >
          -1
      );
    },
    createPresentedDocument() {
      const presented_documents = this.selected?.ware_shipments[this.shipmentIndex].presented_documents ?? []
      if (!presented_documents.length || !presented_documents.some(i => i.doc_code === "09024")) {
        this.$store.dispatch("epi/addShipmentDocument").then((res) => {
          const payload = convertEmptyStringsToNull({
            ...res.data,
            doc_code: "09024",
            doc_number: this.certificate_info.certificate_number,
            doc_date: this.certificate_info.certificate_date
          });
          return this.$store.dispatch("epi/updateShipmentDocument", {
            payload,
          })
        }).then(() => {
          this.$snackbar({
            text: 'Добавлен документ 09024',
            top: false,
          })
        })
      }
    },
    uploadData() {
      const index = this.index;
      const payload = convertEmptyStringsToNull({
        ...this.transport_item,
        country_name: getCountryNameByCode(
          this.countries,
          this.transport_item.country_letter
        ),
        transport_mark_name: getTransportMarkNameByCode(
          this.nsiTransportMarks,
          this.transport_item.transport_mark_code
        ),
      });
      return this.$store.dispatch("epi/updateTransport", {
        index,
        payload,
      }).then(res => {
        this.saveToCatalog()
        if (this.certificate_info.certificate_number) this.createPresentedDocument()
        return res
      });
    },
  },
};
</script>

<style scoped>
td {
  padding-left: 0 !important;
  border-bottom: none !important;
  padding-bottom: 10px !important;
  padding-right: 10px !important;
}
</style>
