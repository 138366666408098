<template>
  <tr
    :id="block_id"
    v-click-outside="triggerOnFocus"
  >
    <td
      v-if="!isOldStructure"
      width="150px"
    >
      <div class="d-flex align-center">
        <v-tooltip
          :disabled="!warranty_item.doc_code || hideTooltips"
          bottom
          max-width="300px"
        >
          <template #activator="{ on }">
            <v-autocomplete
              v-model="warranty_item.doc_code"
              auto-select-first
              :items="documentTypes"
              item-text="code"
              item-value="code"
              :filter="filterBySearchField"
              outlined
              dense
              hide-details="auto"
              background-color="white"
              v-on="on"
            />
          </template>
          <span>{{ docNameDocTypes }}</span>
        </v-tooltip>
        <v-btn
          tabindex="-1"
          min-width="36px"
          max-width="36px"
          color="#5CB7B1"
          class="ml-1 elevation-0"
          @click="showDocuments"
        >
          <v-icon>
            mdi-format-list-bulleted
          </v-icon>
        </v-btn>
      </div>
    </td>
    <td
      v-if="isOldStructure"
      width="90px"
    >
      <v-tooltip
        bottom
        max-width="300px"
        :disabled="!warranty_item.code || hideTooltips"
      >
        <template #activator="{ on }">
          <v-autocomplete
            ref="code"
            v-model="warranty_item.code"
            item-text="text"
            item-value="code"
            :filter="filterBySearchField"
            :items="guaranteeTypes"
            auto-select-first
            background-color="white"
            dense
            outlined
            hide-details="true"
            v-on="on"
            @change="onCodeChange"
          />
        </template>
        <span>{{ docNameGuarantee }}</span>
      </v-tooltip>
    </td>
    <td
      v-if="!isOldStructure"
      width="230px"
    >
      <v-autocomplete
        ref="code"
        v-model="warranty_item.code"
        auto-select-first
        :items="types"
        item-text="text"
        item-value="code"
        outlined
        background-color="white"
        dense
        hide-details="auto"
        :filter="filterBySearchField"
      />
    </td>
    <td width="220px">
      <v-text-field
        ref="number"
        v-model="warranty_item.number"
        :disabled="selectedCode('00','06','63')"
        :background-color="selectedCode('00','06','63') ? 'grey lighten-2': 'white'"
        class="mr-1"
        outlined
        dense
        hide-details="auto"
        maxlength="50"
      />
    </td>
    <td>
      <custom-date-picker
        ref="date"
        v-model="warranty_item.date"
        :visited.sync="fields_been_visited"
        :has-changes.sync="hasChanges"
        :disabled="selectedCode('00','06','63')"
        :background-color="selectedCode('00','06','63') ? 'grey lighten-2': 'white'"
      />
    </td>
    <td width="200px">
      <div class="d-flex">
        <v-text-field
          ref="sum"
          v-model="warranty_item.sum"
          :disabled="selectedCode('00','06','07', '08','63')"
          class="mr-1"
          outlined
          dense
          hide-details="auto"
          :background-color="selectedCode('00','06','07', '08','63')? 'grey lighten-2': 'white'"
          @input="(v) => (warranty_item.sum = v.replace(',', '.'))"
          @change="onSumChange"
        />
        <v-autocomplete
          ref="currency_letter"
          v-model="warranty_item.currency_letter"
          :disabled="selectedCode('00','06','07', '08','63')"
          :background-color="selectedCode('00','06','07', '08','63')?'grey lighten-2': 'white'"
          style="width: 75%"
          auto-select-first
          :items="currencies"
          item-text="letterCode"
          item-value="letterCode"
          outlined
          dense
          hide-details="auto"
          :filter="filterBySearchField"
        />
      </div>
    </td>
    <td
      v-if="isOldStructure"
      width="120px"
    >
      <v-text-field
        ref="inn"
        v-model="warranty_item.inn"
        v-mask="'#########'"
        :disabled="selectedCode('00','03','06', '07', '08','63')"
        :background-color="selectedCode('00','03','06', '07', '08','63')? 'grey lighten-2': 'white'"
        class="mr-1"
        outlined
        dense
        hide-details="auto"
      />
    </td>
    <td
      v-if="isOldStructure"
      width="120px"
    >
      <v-autocomplete
        ref="bank_id"
        v-model="warranty_item.bank_id"
        :disabled="selectedCode('00','03','06', '07', '08','63')"
        :background-color="selectedCode('00','03','06', '07', '08','63') ? 'grey lighten-2': 'white'"
        item-text="CDBank"
        item-value="CDBank"
        :items="banks"
        auto-select-first
        dense
        outlined
        hide-details="true"
      />
    </td>
    <td v-if="isOldStructure">
      <v-text-field
        ref="subject_name"
        v-model="warranty_item.subject_name"
        :disabled="selectedCode('00','03','06', '07', '08', '63')"
        :background-color="selectedCode('00','03','06', '07', '08', '63') ? 'grey lighten-2': 'white'"
        class="mr-1"
        outlined
        dense
        hide-details="auto"
        maxlength="120"
      />
    </td>
    <td
      colspan="3"
    >
      <v-btn
        v-if="!warranty_item.so_id"
        :disabled="!selectedCode('03')"
        color="#5CB7B1"
        class="mr-1"
        @click="addNewCertificate"
      >
        <v-icon>mdi-plus</v-icon>
        Серт.
      </v-btn>
      <div
        v-else
        class="d-flex"
      >
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              height="40"
              min-width="40"
              max-width="50"
              color="#5CB7B1"
              class="elevation-0 rounded-r-0"
              v-on="on"
              @click="openCertificate(warranty_item.so_id)"
            >
              <v-icon>mdi-open-in-new</v-icon>
            </v-btn>
          </template>
          Перейти к сертификату
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              height="40"
              min-width="40"
              max-width="50"
              dark
              class="elevation-0 rounded-l-0"
              @click="fillStateFromEnsuring(warranty_item.so_id)"
              v-on="on"
            >
              <v-icon>mdi-content-duplicate</v-icon>
            </v-btn>
          </template>
          Вставить данные из СО
        </v-tooltip>
      </div>
    </td>
    <td>
      <div class="d-flex">
        <v-btn
          text
          height="40"
          min-width="40"
          max-width="40"
          small
          @click="deleteWarranty"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn
              :class="{'blocked-box-exclude':disabledView }"
              v-bind="attrs"
              height="40"
              min-width="40"
              max-width="40"
              text
              v-on="on"
            >
              <v-icon>
                mdi-tune
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="showWarrantyApplicationModal"
            >
              Cформировать заявку
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          v-show="index === itemsAmount - 1"
          text
          height="40"
          min-width="40"
          max-width="40"
          small
          @click="addNewWarranty"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </td>
    <component
      :is="activeComponent"
      :item="item"
      :show.sync="show"
    />
  </tr>
</template>

<script>
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import CustomDatePicker from "@/components/shared/custom-date-picker.vue";
import {convertEmptyStringsToNull} from "@/helpers/objects";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import {normalizeFloat} from "@/helpers/math";
import {mapGetters} from "vuex";
import highlightFieldsChild from "@/mixins/control/highlight-fields-child";
import InputNumeric from "@/components/ui/input-numeric.vue";
import {
  fillStateByCarrier,
  fillStateFromDeclarant,
  findCarrierByUnp, getActiveDivisionId, getCarrierUnp,
  getDeclarantUnp, getOperatorByUnp,
  fillStateFromEnsuring
} from "@/helpers/warranty-fill-state";
import {showCatalog} from "@/events/statistics";
import {eventBus} from "@/bus";

export default {
  components: {InputNumeric, CustomDatePicker},
  mixins: [filterBySearchFieldMixin, blockAutoUpdate, highlightFieldsChild],
  props: {
    banks: {
      required: true,
      type: Array,
    },
    item: {
      required: true,
      type: Object,
    },
    index: {
      required: true,
      type: Number,
    },
    hideTooltips: {
      required: true,
      type: Boolean,
    },
    itemsAmount: {
      required: true,
      type: Number,
    },
    guaranteeTypes: {
      required: true,
      type: Array,
    },
  },
  data: () => ({
    block_id: null,
    show:false,
    activeComponent:null,
    warranty_item: {
      bank_id: "",
      code: "",
      date: "",
      declaration_id: null,
      id: null,
      inn: "",
      number: "",
      subject_name: "",
      sum: "",
      so_id: "",
      currency_letter: "",
      doc_code: "",
    },
  }),
  computed: {
    ...mapGetters({
      currencies: "catalogs/getNsiCurrencies",
      selected:'epi/getSelected',
      aeos:'epi/getNsiAeos',
      user:'auth/getUser',
      disabledView:"epi/getVisibility",
      documentTypes: "catalogs/getNsiTypesOfDocument",
      isOldStructure: "epi/getIsOldStructure",
      types:"catalogs/gethNsiGuaranteeTypesSo",
    }),
    docNameGuarantee() {
      const {name} =
      this.guaranteeTypes.find((i) => i.code === this.warranty_item.code) ||
      {};
      return name || null;
    },
    docNameDocTypes() {
      const {name} =
      this.documentTypes.find((i) => i.code === this.warranty_item.doc_code) || {};
      return name || null;
    },
  },
  watch: {
    item: {
      handler(newVal) {
        this.block_id = `epi-warranty-item-${this.item.id}`;
        for (let key in newVal) {
          if (key in this.warranty_item) {
            this.warranty_item[key] = newVal[key];
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    eventBus.$on("warranty-item-update", this.updateDocType)
  },
  beforeDestroy() {
    eventBus.$off("warranty-item-update", this.updateDocType)
  },
  methods: {
    fillStateFromDeclarant,
    findCarrierByUnp,
    fillStateByCarrier,
    getDeclarantUnp,
    getOperatorByUnp,
    getCarrierUnp,
    getActiveDivisionId,
    fillStateFromEnsuring,
    showWarrantyApplicationModal(){
      this.activeComponent = () => import('./warranty-application.vue')
      this.show = true
    },
    selectedCode(...codes) {
      return codes.includes(this.warranty_item.code)
    },
    onCodeChange(){
      this.modifyItemData()
    },
    modifyItemData(){
      if(this.selectedCode("03")){
        this.warranty_item = {
          ...this.warranty_item,
          inn:null,
          bank_id: null,
          subject_name: null
        }
      }else if(this.selectedCode("00","06", "63")){
        Object.keys(this.warranty_item).forEach(key => {
          if(!['id', 'code', 'declaration_id'].includes(key)){
            this.warranty_item[key] = null
          }
        })
      } else if(this.selectedCode("07")){
        this.fillStateFromDeclarant()
      } else if(this.selectedCode( "08")){
        this.findCarrierByUnp().then(this.fillStateByCarrier)
      }
    },
    openCertificate(id) {
      const route = this.$router.resolve({
        path: `/ensuring/${id}`,
      });
      window.open(route.href, "_blank");
    },
    addNewCertificate() {
      this.$store
        .dispatch(
          "epi/createCertificate",
          this.warranty_item.declaration_id
        )
        .then((res) => {
          const id = res.data;
          this.openCertificate(id);
          this.attachCertificateToWarranty(id);
        })
        .catch(() => {
          this.$snackbar({text: "Ошибка", color: "red", top: false});
        });
    },
    attachCertificateToWarranty(id) {
      this.warranty_item.so_id = id;
      this.uploadData()
        .then(() => {
          this.fields_been_visited = false;
          this.hasChanges = false;
          this.$snackbar({
            text: "Обновлено [Гарантия]",
            color: "green",
            top: false,
          });
        })
        .catch(() => {
          this.$snackbar({text: "Ошибка", color: "red", top: false});
        });
    },
    onSumChange(value) {
      this.warranty_item.sum = normalizeFloat({value, afterPoint: 2});
    },
    deleteWarranty() {
      this.$store.dispatch("epi/deleteWarranty", this.warranty_item.id);
    },
    addNewWarranty() {
      this.$store.dispatch(
        "epi/createWarranty",
        this.warranty_item.declaration_id
      );
    },
    readyToUpdate(){
      this.fields_been_visited = true
      this.hasChanges = true
    },
    uploadData() {
      const index = this.index;
      const payload = convertEmptyStringsToNull({
        ...this.warranty_item,
      });
      return this.$store.dispatch("epi/updateWarranty", {
        index,
        payload,
      });
    },
    updateDocType({id, code}){
      if(id === this.warranty_item.id){
        this.warranty_item.doc_code = code;
        this.readyToUpdate()
      }
    },
    showDocuments() {
      showCatalog.trigger({
        type: "doctypes",
        id: this.warranty_item.id,
        search: this.warranty_item.doc_code,
        customEventName: "warranty-item-update"
      });
    },
  },
};
</script>

<style scoped>
td {
  padding: 0 10px 10px 0 !important;
  border-bottom: none !important;
}
</style>
