<template>
  <div
    :id="block_id"
    v-click-outside="triggerOnFocus"
    class="document__row"
    :draggable="draggable"
    @dragstart="startDrag($event, document)"
    @mouseup="mouseUp"
  >
    <div class="document__select">
      <v-checkbox
        tabindex="-1"
        dense
        class="pa-0 ml-1"
        hide-details
        color="#5CB7B1"
        :value="selectedDocs.includes(item.id)"
        @change="updateSelected"
      />
    </div>

    <div class="document__sort">
      <div
        class="allow-drag"
        @mousedown="mouseDown($event)"
      >
        {{ document.sort_index }}
      </div>
    </div>

    <div class="document__code">
      <v-text-field
        v-show="customFieldChecker(doc_types, document.doc_code, 'code')"
        ref="doc_code"
        v-model="document.doc_code"
        v-mask="'XXXXX'"
        :label="index === 0 ? 'Код' : ''"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
      <v-tooltip
        bottom
        max-width="300px"
        :disabled="!document.doc_code || hideTooltips"
      >
        <template #activator="{ on }">
          <v-autocomplete
            v-show="!customFieldChecker(doc_types, document.doc_code, 'code')"
            ref="doc_code__custom"
            v-model="document.doc_code"
            auto-select-first
            :items="doc_types"
            item-text="code"
            :filter="filterBySearchField"
            :label="index === 0 ? 'Код' : ''"
            item-value="code"
            outlined
            dense
            hide-details="auto"
            background-color="white"
            v-on="on"
          />
        </template>
        <span>{{ docName(document.doc_code) }}</span>
      </v-tooltip>

      <v-btn
        tabindex="-1"
        min-width="36px"
        max-width="36px"
        color="#5CB7B1"
        class="ml-1 elevation-0"
        @click="showDocuments"
      >
        <v-icon> mdi-format-list-bulleted </v-icon>
      </v-btn>
    </div>
    <div
      v-show="!isOldStructure"
      class="present_type_code"
    >
      <v-autocomplete
        v-model="presenting_details_json.present_type_code"
        auto-select-first
        outlined
        :label="index === 0 ? 'Признак' : ''"
        dense
        hide-details="auto"
        item-text="text"
        item-value="code"
        :items="presentTypesFiltered"
        background-color="white"
      />
    </div>

    <div class="document__number">
      <v-text-field
        v-show="document.doc_code !== '03031'"
        ref="doc_number"
        v-model="document.doc_number"
        :label="index === 0 ? 'Номер документа' : ''"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
      <v-text-field
        v-show="document.doc_code === '03031'"
        ref="doc_number__custom"
        :disabled="document.deal_no_required"
        :value="document.doc_number"
        :label="index === 0 ? 'Номер документа' : ''"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        @input="useDocumentMask"
      />
    </div>

    <div class="document__date">
      <custom-date-picker
        ref="doc_date"
        v-model="document.doc_date"
        :visited.sync="fields_been_visited"
        :has-changes.sync="hasChanges"
        :label="index === 0 ? 'Дата документа' : ''"
      />
    </div>

    <div class="document__range">
      <v-text-field
        ref="ware_range"
        v-model="range"
        placeholder="Все товары"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        @change="onRangeChange"
      />
    </div>
    <div v-show="!isOldStructure">
      <v-menu
        offset-x
        :close-on-content-click="false"
      >
        <template #activator="{ on: menu }">
          <v-tooltip bottom>
            <template #activator="{ on: tooltip }">
              <v-btn
                class="ml-1 mr-3"
                color="grey lighten-2"
                max-height="32px"
                min-width="32px"
                max-width="32px"
                v-on="{ ...tooltip, ...menu }"
              >
                <v-icon>
                  {{ showAdditionalFields ? "mdi-chevron-up" : "mdi-chevron-down" }}
                </v-icon>
              </v-btn>
            </template>
            <span>Развернуть доп.поля</span>
          </v-tooltip>
        </template>
        <v-list
          dense
          outlined
          class="pa-3"
        >
          <v-text-field
            v-model="document.doc_name"
            label="Наименование"
            outlined
            dense
            hide-details="auto"
            background-color="white"
            class="mb-3"
          />
          <custom-date-picker
            ref="doc_date"
            v-model="document.doc_start_date"
            :visited.sync="fields_been_visited"
            :has-changes.sync="hasChanges"
            label="Дата начала"
            background-color="white"
            class="mb-3"
          />
          <custom-date-picker
            ref="doc_date"
            v-model="document.doc_validity_date"
            :visited.sync="fields_been_visited"
            :has-changes.sync="hasChanges"
            label="Дата окончания"
            background-color="white"
          />
        </v-list>
      </v-menu>
    </div>
    <div
      v-if="isTypeCode0 && !isOldStructure"
      class="document__code"
    >
      <v-tooltip
        bottom
        max-width="300px"
        :disabled="!presenting_details_json.doc_code || hideTooltips"
      >
        <template #activator="{ on }">
          <v-autocomplete
            ref="presenting_doc_code"
            v-model="presenting_details_json.doc_code"
            auto-select-first
            :items="doc_types"
            item-text="code"
            item-value="code"
            :filter="filterBySearchField"
            placeholder="Код"
            outlined
            dense
            hide-details="auto"
            background-color="white"
            v-on="on"
          />
        </template>
        <span>{{ docName(presenting_details_json.doc_code) }}</span>
      </v-tooltip>
    </div>
    <!-- document.doc_code === 02024 -->
    <div v-if="document.doc_code === '02024'">
      <div class="document__addition d-flex">
        <v-text-field
          v-model="document.tir_page_number"
          placeholder="Номер листа"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          class="mr-2"
        />
        <v-text-field
          v-model="document.tir_owner_inn"
          placeholder="ИН держателя"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </div>
    </div>
    <!-- presenting_details_json.doc_code === 02024 -->
    <div
      v-if="presenting_details_json.doc_code === '02024'"
      class="document__code"
    >
      <v-text-field
        v-model="presenting_details_json.tir_number"
        label="Книжка МДП"
        dense
        outlined
        hide-details="auto"
        background-color="white"
      />
    </div>
    <!-- document.doc_code === 01401, 01402 -->
    <div
      v-show="document.doc_code === '01401' || document.doc_code === '01402'"
      class="document__customs__code"
    >
      <v-autocomplete
        v-model="document.customs_code"
        auto-select-first
        placeholder="Код таможенного органа"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        item-text="search"
        item-value="code"
        :items="customsPoints"
      />
    </div>
    <!-- presenting_details_json.doc_code === "09013", "09035", "10044" -->
    <div
      v-if="isTypeCode0 && isSpecificCode && !isOldStructure"
      class="d-flex"
    >
      <div class="document__number">
        <v-text-field
          ref="customs_doc_number"
          v-model="presenting_details_json.customs_doc_number"
          v-mask="maskCustomsDocNumber"
          outlined
          dense
          placeholder="Рег. №"
          hide-details="auto"
          background-color="white"
        />
      </div>
    </div>
    <!-- presenting_details_json.doc_code === 09036 -->
    <div
      v-if="presenting_details_json.doc_code === '09036' && !isOldStructure"
      class="d-flex"
    >
      <div class="document__number">
        <v-text-field
          ref="dtm_number"
          v-model="presenting_details_json.dtm_number"
          outlined
          dense
          placeholder="№ декларации на ТС"
          hide-details="auto"
          background-color="white"
        />
      </div>
      <div class="document__code">
        <v-autocomplete
          ref="vehicle_type"
          v-model="presenting_details_json.vehicle_type"
          :items="vehicleTypes"
          :filter="filterBySearchField"
          item-text="text"
          item-value="code"
          placeholder="Код вида ТС"
          auto-select-first
          dense
          outlined
          hide-details="auto"
          background-color="white"
        />
      </div>
    </div>
    <!-- Если presenting_details_json.doc_code !== 09013, 09035, 10044, 09036, 02024 -->
    <div
      v-if="isNoSpecificCode && !isOldStructure && isTypeCode0"
      class="document__addition d-flex"
    >
      <div class="preceding__document__number">
        <v-text-field
          ref="preceding_doc_number"
          v-model="presenting_details_json.preceding_doc_number"
          outlined
          dense
          placeholder="Номер документа"
          hide-details="auto"
          background-color="white"
        />
      </div>

      <custom-date-picker
        ref="preceding_doc_date"
        v-model="presenting_details_json.preceding_doc_date"
        :visited.sync="fields_been_visited"
        :has-changes.sync="hasChanges"
        label="Дата"
        background-color="white"
      />
    </div>

    <v-btn
      tabindex="-1"
      max-width="36px"
      min-width="36px"
      color="transparent"
      class="elevation-0 document-item-delete"
      @click="deleteDocument"
    >
      <v-icon>mdi-delete</v-icon>
    </v-btn>

    <v-menu offset-x>
      <template #activator="{ on }">
        <v-btn
          tabindex="-1"
          max-width="36px"
          min-width="36px"
          color="transparent"
          class="elevation-0 document-item-menu"
          v-on="on"
        >
          <v-icon>mdi-tune</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          disabled
          tag="button"
        >
          Привязать документ к резиденту
        </v-list-item>
        <v-list-item
          disabled
          tag="button"
        >
          Привязать документ к нерезиденту
        </v-list-item>
        <v-list-item
          v-if="!customFieldChecker(doc_types, document.doc_code, 'code')"
          tag="button"
          @click="document.doc_code = ''"
        >
          Произвольное значение
        </v-list-item>
        <v-list-item
          v-else
          tag="button"
          @click="document.doc_code = null"
        >
          Значение из справочника
        </v-list-item>
        <v-list-item
          @click="openGovRegistrationsCatalog"
        >
          <v-icon>mdi-magnify</v-icon> Поиск в реестре СГР
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- last child -->
    <!-- {{ presentedDocuments }} -->
    <v-btn
      v-if="index === presentedDocuments.length - 1"
      class="document-add elevation-0"
      color="transparent"
      min-width="36px"
      max-width="36px"
      @click="addNewDoc"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <component
      :is="activeComponent"
      :show.sync="show"
      :item="document"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { deleteNulls } from "@/helpers/inputs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { convertEmptyStringsToNull } from "@/helpers/objects";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import {
  isGoodsRangeValid,
  isCorrectStartEnd,
  trimRange,
} from "@/helpers/inputs";
import { showCatalog } from "@/events/statistics";
import { documentTypeSelected } from "@/events/statistics";
import CustomDatePicker from "@/components/shared/custom-date-picker";
import { DEAL_REGISTRATION_NOT_REQUIRED } from "@/constants";
import highlightFieldsChild from "@/mixins/control/highlight-fields-child";
import cloneDeep from "lodash.clonedeep";

export default {
  components: {
    CustomDatePicker,
  },
  mixins: [blockAutoUpdate, filterBySearchFieldMixin, highlightFieldsChild],
  props: {
    index: {
      required: true,
      type: Number,
    },
    item: {
      required: true,
      type: Object,
    },
    draggable: {
      required: true,
      type: Boolean,
    },
    forSingleItem: {
      required: true,
      type: Boolean,
    },
    hideTooltips: {
      required: true,
      type: Boolean,
    },
    selectedDocs: {
      required: true,
      type: Array,
    },
    presentedDocuments: {
      required: true,
      type: Array,
    },
  },
  data: () => ({
    activeComponent:false,
    show:false,
    selected: null,
    mousePressed: false,
    isIntersecting: false,
    parent: null,
    block_id: null,
    menu: false,
    document: {
      id: "",
      ware_shipment_id: "",
      doc_code: "",
      doc_number: "",
      doc_date: "",
      customs_code: "",
      ware_number_from_dt: "",
      tir_page_number: "",
      tir_owner_inn: "",
      ware_range: "",
      sort_index: "",
      doc_name: null,
      doc_start_date: null,
      doc_validity_date: null,
    },
    presenting_details_json: {
      present_type_code: "",
      doc_code: null,
      customs_doc_number: null,
      dtm_number: null,
      vehicle_type: null,
      tir_number: "",
      preceding_doc_number: "",
      preceding_doc_date: ""
    },
    range: "",
    noRegistration: true,
    key: "",
    noRegistrationText: DEAL_REGISTRATION_NOT_REQUIRED,
    showAdditionalFields: false,
  }),

  computed: {
    ...mapGetters({
      doc_types: "catalogs/getNsiTypesOfDocument",
      customsPoints: "catalogs/getNsiCustomsPoints",
      presentTypes: "catalogs/getNsiPresentTypes",
      isOldStructure: "epi/getIsOldStructure",
      vehicleTypes:"catalogs/getNsiVehicleTypes",
    }),
    presentTypesFiltered(){
      return this.presentTypes.filter((i) => ['0', '1'].includes(i.code))
    },
    isSpecificCode() {
      return ["09013", "09035", "10044"].includes(this.presenting_details_json.doc_code);
    },
    isNoSpecificCode() {
      return !['09013', '09035', '10044', '09036', '02024'].includes(this.presenting_details_json.doc_code);
    },
    isTypeCode0() {
      return this.presenting_details_json.present_type_code === "0";
    },
    maskCustomsDocNumber() {
      switch (this.document.doc_code) {
      case "09013":
        return '########/######/#######/##';
      case "09035":
        return '#####/######/#######/##';
      case "10044":
        return '##/######/#######/##';
      default:
        return 'X'.repeat(20)
      }
    },
  },
  watch: {
    show(nv){
      if(nv === false) this.activeComponent = null
    },
    "document.doc_code": {
      handler(nv) {
        if (
          (nv === "03031" &&
            !this.checkDocumentMask(this.document.doc_number) &&
            !this.document.deal_no_required) ||
          (nv !== "03031" &&
            this.document.doc_number === DEAL_REGISTRATION_NOT_REQUIRED) //Если был 03031 то очищаем поле в случае "не трубует регистрации"
        ) {
          this.document.doc_number = "";
          this.document.deal_no_required = null;
        }
      },
    },
    range(nv) {
      this.validateRange(nv.replace(".", ","));
    },
    item: {
      handler() {
        this.block_id = `epi-present-document-${this.item.id}`;
        this.setFields();
        // this.range = newVal?.ware_range ?? "";
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    documentTypeSelected.subscribe(this.checkAndUpdateDocType);
    const collection = [...document.getElementsByClassName("drop-zone")];
    const [parent] = collection;
    this.parent = parent;
  },
  beforeDestroy() {
    documentTypeSelected.unsubscribe();
  },
  methods: {
    setFields() {
      const document = this.item;
      const presenting_details_json = this.item.presenting_details_json;
      this.presenting_details_json = cloneDeep(presenting_details_json);

      Object.keys(this.document).forEach((key) => {
        if (key in document) {
          this.document[key] = document[key];
        }
      });
    },
    docName(code) {
      const { name } = this.doc_types.find((i) => i.code === code) || {};
      return name || "";
    },
    openGovRegistrationsCatalog(){
      showCatalog.trigger({
        type: "government_registrations",
        search: this.document.doc_number,
      })
    },
    checkDocumentMask(value) {
      const regex = /([0-9]{6}\/[0-9]{6}\/[0-9]{5})/g;
      return new RegExp(regex).test(value);
    },
    useDocumentMask(value) {
      // mask = ######/######/#####
      if (this.document.doc_code === "03031") {
        const symbols = value.split("");
        const errors = symbols.filter(
          (i) => i !== "/" && Number.isNaN(Number.parseInt(i))
        );
        if (errors.length || value.length > 19) {
          const { doc_number } = this.document;
          this.document.doc_number = ""; // Чтобы значение в v-text-field обновилось
          this.$nextTick(() => {
            this.document.doc_number = doc_number;
          });
        } else {
          if (
            value.length > this.document.doc_number?.length &&
            (value.length === 7 || value.length === 14)
          ) {
            const symbols = value.split("").reverse();
            const [end, ...start] = symbols;
            this.document.doc_number = [...start.reverse(), "/", end].join("");
          } else {
            this.document.doc_number = value;
          }
        }
      } else {
        this.document.doc_number = value;
      }
    },
    onRangeChange(value) {
      this.range = trimRange(value);
    },
    addNewDoc() {
      this.$emit("addNewDoc");
    },
    customFieldChecker(items, field, param) {
      if (field === null) {
        return false;
      } else if (field === "") {
        return true;
      } else {
        var positiveArr = items.filter(function (i) {
          return i[param] === field;
        });
        if (positiveArr.length) {
          return false;
        } else {
          return true;
        }
      }
    },
    updateSelected() {
      this.$emit("select", this.item.id);
    },
    validateRange(nv) {
      const result = isGoodsRangeValid(nv);
      if (result !== false) {
        this.document.ware_range = result;
        this.range = result;
      } else {
        this.$nextTick(() => {
          this.range = this.document.ware_range;
        });
      }
    },
    startDrag(evt, document) {
      if (this.hasChanges && this.fields_been_visited) {
        return this.$snackbar({
          text: "Сохраните изменения перед сортировкой.",
          color: "orange",
          top: false,
          right: false,
        });
      }
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("document", document.id);
      // console.log('get', evt.dataTransfer.getData("document"))
    },
    mouseUp() {
      this.$emit("update:draggable", false);
    },
    mouseDown(evt) {
      // Перетаскивание только в режиме просмотра всех товаров
      if (
        [...evt.target.classList].includes("allow-drag") &&
        !this.forSingleItem
      ) {
        this.$emit("update:draggable", true);
      }
    },
    deleteNulls,
    deleteDocument() {
      const { id } = this.item;
      const index = this.index;
      this.$emit("delete", {
        id,
        index,
      });
    },
    showVehicleUpdateModal(){
      this.activeComponent = () => import('@/components/epi/form/documents/update-vehicle-document.vue')
      this.show = true
    },
    uploadData() {
      if (!isCorrectStartEnd(this.document.ware_range)) {
        this.processing = false; //  Если вышли из блока когда не верный диапазон то надо разрешить отправку повторно
        return this.$snackbar({
          text: "Неверное начало и конец диапазона товаров",
          color: "red",
          top: false,
          right: false,
        });
      }
      const index = this.index;
      const payload = convertEmptyStringsToNull({
        ...this.document,
        presenting_details_json: this.presenting_details_json
      });
      return this.$store.dispatch("epi/updateShipmentDocument", {
        index,
        payload,
      }).then((res) => {
        if(this.document.doc_code === "09024") this.showVehicleUpdateModal()
        return res
      });
    },
    showDocuments() {
      showCatalog.trigger({
        type: "doctypes",
        id: this.document.id,
        search: this.document.doc_code,
      });
    },
    checkAndUpdateDocType({ id, code }) {
      if (this.document.id === id) {
        this.document.doc_code = code;
        this.fieldsBeenVisitedByUser();
      }
    },
  },
};
</script>
<style scoped>
.document__row {
  display: flex;
  align-items: center;
  padding-top: 4px;
}
.document__select {
  display: flex;
  justify-content: center;
  align-items: center;
}
.document__sort {
  width: 40px !important;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}
.document__code {
  margin-right: 10px;
  width: 140px !important;
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
}
.document__number {
  width: 230px !important;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}
.document__date {
  width: 160px !important;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}
.document__range {
  width: 200px !important;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}
.document__addition {
  width: 300px !important;
  margin-right: 10px;
}
.document__customs__code {
  margin-right: 10px;
  width: 250px !important;
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
}
.present_type_code {
  max-width: 80px;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}
.preceding__document__number {
  width: 205px !important;
  margin-right: 10px;
}
.h-full {
  height: 100%;
}
.document-row:last-child {
  padding: 0 10px 4px 0 !important;
}
.allow-drag {
  cursor: pointer !important;
}
</style>
