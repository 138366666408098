<template>
  <v-container fluid>
    <v-row>
      <v-col
        id="epi-guarantee"
        class="statistics-box statistics-guarantee-list mx-3 my-2"
        :class="{'blocked-box' : disabledView}"
      >
        <v-row>
          <v-col
            cols="12"
            class="pb-0"
          >
            <div class="font-weight-bold d-flex align-center mt-2">
              52 <span class="ml-1">{{ isOldStructure ? " Гарантия" : " Обеспечение" }}</span>
              <v-menu offset-x>
                <template #activator="{ on, attrs }">
                  <v-icon
                    tabindex="-1"
                    class="mx-1"
                    small
                    dense
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-tune
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item @click="hideTooltips = !hideTooltips">
                    {{ hideTooltips ? "Включить" : "Отключить" }} подсказки
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
          <v-col cols="12">
            <v-row
              :id="block_id"
              v-click-outside="triggerOnFocus"
            >
              <v-col cols="2">
                <label>Сумма платежей</label>
                <input-numeric
                  :value="common_payment_sum_byn"
                  class="mr-1"
                  outlined
                  dense
                  readonly
                  hide-details="auto"
                  background-color="grey lighten-2"
                  suffix="BYN"
                />
              </v-col>
              <v-col cols="2">
                <label>Сумма платежей</label>
                <input-numeric
                  :value="common_payment_sum_eur"
                  class="mr-1"
                  outlined
                  dense
                  readonly
                  hide-details="auto"
                  background-color="grey lighten-2"
                  suffix="EUR"
                />
              </v-col>
              <v-col
                v-if="!isOldStructure"
                cols="2"
              >
                <label>Код предоставления</label>
                <v-autocomplete
                  ref="doc_usage_code"
                  v-model="about_guarantee.guarantee_present_code"
                  :items="provision_codes"
                  item-text="text"
                  item-value="code"
                  auto-select-first
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                  class="mr-1 p-0"
                />
              </v-col>
              <v-col
                v-if="!isOldStructure"
                cols="3"
              >
                <label>Основание непредоставления</label>
                <div class="d-flex align-center">
                  <v-tooltip
                    bottom
                    max-width="300px"
                    :disabled="!about_guarantee.no_guarantee_cause_code || hideTooltips"
                  >
                    <template #activator="{ on }">
                      <v-autocomplete
                        ref="code"
                        v-model="about_guarantee.no_guarantee_cause_code"
                        item-text="search"
                        item-value="code"
                        :filter="filterBySearchField"
                        :items="guaranteeNonUseReasons"
                        auto-select-first
                        dense
                        outlined
                        :disabled="about_guarantee.guarantee_present_code === '1'"
                        hide-details="auto"
                        :background-color="about_guarantee.guarantee_present_code !== '1' ? 'white' : 'grey lighten-2'"
                        v-on="on"
                      />
                    </template>
                    <span>{{ nameGuaranteeNonUseReasons }}</span>
                  </v-tooltip>
                  <v-btn
                    tabindex="-1"
                    min-width="36px"
                    max-width="36px"
                    color="#5CB7B1"
                    class="ml-1 elevation-0"
                    @click="openNoGuaranteeReasons"
                  >
                    <v-icon> mdi-format-list-bulleted </v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <div>
              <v-simple-table
                v-if="warrantyItems.length"
                class="bg-gray"
                fixed-header
                dense
              >
                <template #default>
                  <thead>
                    <tr>
                      <th v-if="!isOldStructure">
                        Код док-та
                      </th>
                      <th v-if="isOldStructure">
                        Код меры
                      </th>
                      <th v-if="!isOldStructure">
                        Код способа
                      </th>
                      <th>Номер документа</th>
                      <th>Дата</th>
                      <th>Сумма гарантии</th>
                      <th v-if="isOldStructure">
                        УНП
                      </th>
                      <th v-if="isOldStructure">
                        Код банка
                      </th>
                      <th v-if="isOldStructure">
                        Лицо, осуществляющее сопровождение
                      </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <warranty-item
                      v-for="(item, index) in warrantyItems"
                      :key="index"
                      :banks="banks"
                      :item="item"
                      :index="index"
                      :items-amount="warrantyItems.length"
                      :guarantee-types="guaranteeTypes"
                      :hide-tooltips="hideTooltips"
                      :control="warrantyControlPath"
                    />
                  </tbody>
                </template>
              </v-simple-table>
              <v-btn
                v-else
                text
                @click="addNewWarranty"
              >
                <v-icon small>
                  mdi-plus
                </v-icon>
                Добавить <span class="ml-1">{{ isOldStructure ? "гарантию" : "документ" }}</span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import WarrantyItem from "@/components/epi/form/warranty/warranty-item.vue";
import { getScrollOffset } from "@/helpers/control";
import { guarantees as onGuarantee } from "@/events/statistics/control";
import InputNumeric from "@/components/ui/input-numeric.vue";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import { showCatalog, catalogValueSelected } from "@/events/statistics";
import cloneDeep from "lodash.clonedeep";

export default {
  components: {InputNumeric, WarrantyItem },
  mixins: [blockAutoUpdate, filterBySearchFieldMixin],
  data: () => ({
    block_id: "epi-warranty",
    collapsed: false,
    banks: [],
    warrantyItems: [],
    hideTooltips: true,
    common_payment_sum_byn: null,
    common_payment_sum_eur: null,
    warrantyControlPath: {
      path: null
    },
    about_guarantee:{
      declaration_id: null,
      guarantee_present_code: null,
      no_guarantee_cause_code: null,
    },
    provision_codes: [
      {
        code: '1',
        text: "1 – обеспечение предоставлено"
      },
      {
        code: '2',
        text: "2 – обеспечение не предоставляется"
      },
    ],
  }),
  computed: {
    ...mapGetters({
      selected: "epi/getSelected",
      guaranteeTypes: "catalogs/gethNsiGuaranteeTypes",
      disabledView:"epi/getVisibility",
      isOldStructure: "epi/getIsOldStructure",
      guaranteeNonUseReasons: "catalogs/getNsiGuaranteeNonUseReasons",
    }),
    nameGuaranteeNonUseReasons() {
      const {name} =
      this.guaranteeNonUseReasons.find((i) => i.code === this.about_guarantee.no_guarantee_cause_code) ||
      {};
      return name || null;
    },
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    },
    "about_guarantee.guarantee_present_code"() {
      this.about_guarantee.no_guarantee_cause_code = null;
    },
  },
  created() {
    this.loadBankBIC();
    onGuarantee.subscribe(this.onControl);
    catalogValueSelected.subscribe(this.updatesResolver, [
      'about_guarantee.no_guarantee_cause_code',
    ])
  },
  beforeDestroy() {
    onGuarantee.unsubscribe();
    catalogValueSelected.unsubscribe()
  },
  methods: {
    readyToUpdate(){
      this.fields_been_visited = true
      this.hasChanges = true
    },
    openNoGuaranteeReasons() {
      showCatalog.trigger({
        type: "no_guarantee_reasons",
        id: this.about_guarantee.declaration_id,
        search: this.about_guarantee.no_guarantee_cause_code,
        field: "about_guarantee.no_guarantee_cause_code"
      });
    },
    updatesResolver({field, code}){
      if(code && field){
        const [parent, child] = field.split('.')
        this[parent][child] = code
        this.readyToUpdate()
      }
    },
    onControl(data) {
      const docs = document.querySelector(".statistics-guarantee-list");
      const offset = getScrollOffset();
      this.$scrollTo(docs, 200, {
        force: true,
        offset,
        onDone: () => this.highlight(data),
      });
    },
    highlight(data) {
      this.warrantyControlPath = data
    },
    loadBankBIC() {
      const uninterceptedAxiosInstance = axios.create();
      return uninterceptedAxiosInstance
        .get("https://www.nbrb.by/api/bic")
        .then((res) => {
          this.banks = res.data.map((item) => {
            return {
              ...item,
              search: `${item.CDBank} - ${item.NmBankShort}`,
            };
          });
        });
    },
    setFields() {
      const about_guarantee = cloneDeep(this.selected.about_guarantee);
      for (let key in about_guarantee) {
        if (key in this.about_guarantee) {
          this.about_guarantee[key] = about_guarantee[key];
        }
      }
      this.warrantyItems = this.selected.guarantees;
      this.common_payment_sum_byn = this.selected.common_payment_sum_byn;
      this.common_payment_sum_eur = this.selected.common_payment_sum_eur;
    },
    addNewWarranty() {
      this.$store.dispatch("epi/createWarranty", this.selected.id);
    },
    async uploadData() {
      const about_guarantee = this.convertEmptyStringsToNull(
        this.about_guarantee
      );

      const aboutGuaranteeResponse = await this.$store.dispatch("epi/uploadBlockData", {
        name: "about_guarantee",
        value: about_guarantee,
      });
      
      return Promise.resolve(aboutGuaranteeResponse);
    },
  },
};
</script>

<style scoped>
.bg-gray {
  background-color: #ededed !important;
}
thead tr th {
  padding-left: 0 !important;
  background-color: #ededed !important;
  box-shadow: none !important;
}
</style>
