<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div
          :id="block_id"
          v-click-outside="triggerOnFocus"
          class="statistics-box pa-4"
          :class="{'blocked-box' : blocked_visibility}"
        >
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col
                  cols="8"
                  class="pb-0"
                >
                  <div class="font-weight-bold d-flex align-center mt-2">
                    Представленный
                    <v-menu offset-x>
                      <template #activator="{ on, attrs }">
                        <v-icon
                          class="mx-1 mr-4"
                          tabindex="-1"
                          small
                          dense
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-tune
                        </v-icon>
                      </template>
                      <v-list>
                        <v-list-item> Поиск в справочнике </v-list-item>
                        <v-list-item @click="fillFromUser">
                          Заполнить из личного кабинета
                        </v-list-item>
                        <v-list-item
                          v-if="allowChangePerformer"
                          @click="changePerformer"
                        >
                          Сменить владельца
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <div class="d-flex">
                    <div
                      style="width: 15%"
                      class="pr-4"
                    >
                      <label> Страна </label>
                      <v-autocomplete
                        ref="country_letter"
                        v-model="filler_json.country_letter"
                        auto-select-first
                        outlined
                        dense
                        hide-details="auto"
                        background-color="white"
                        :items="countries"
                        item-text="letterCode"
                        item-value="letterCode"
                        :filter="filterBySearchField"
                      />
                    </div>
                    <div style="width: 85%">
                      <label> Код вида док. </label>
                      <v-autocomplete
                        ref="identity_doc_name"
                        v-model="filler_json.identity_doc_name"
                        auto-select-first
                        outlined
                        dense
                        hide-details="auto"
                        background-color="white"
                        :items="identityDocumentsFiltered"
                        item-text="search"
                        item-value="name"
                        :filter="filterBySearchField"
                      />
                    </div>
                  </div>
                </v-col>
                <v-col>
                  <label>
                    Номер документа
                    <v-text-field
                      ref="identity_doc_number"
                      v-model="filler_json.identity_doc_number"
                      outlined
                      dense
                      hide-details="auto"
                      background-color="white"
                    />
                  </label>
                </v-col>
                <v-col>
                  <label>
                    Дата выдачи
                    <custom-date-picker
                      ref="identity_doc_d_on"
                      v-model="filler_json.identity_doc_d_on"
                      :has-changes.sync="hasChanges"
                      :visited.sync="fields_been_visited"
                    />
                  </label>
                </v-col>
                <v-col cols="3">
                  <label>
                    Должность
                    <v-text-field
                      ref="position"
                      v-model="filler_json.position"
                      outlined
                      dense
                      hide-details="auto"
                      background-color="white"
                    />
                  </label>
                </v-col>
                <v-col cols="2">
                  <label>
                    Фамилия
                    <v-text-field
                      ref="last_name"
                      v-model="filler_json.last_name"
                      outlined
                      dense
                      hide-details="auto"
                      background-color="white"
                    />
                  </label>
                </v-col>
                <v-col cols="2">
                  <label>
                    Имя
                    <v-text-field
                      ref="first_name"
                      v-model="filler_json.first_name"
                      outlined
                      dense
                      hide-details="auto"
                      background-color="white"
                    />
                  </label>
                </v-col>
                <v-col cols="2">
                  <label>
                    Отчество
                    <v-text-field
                      ref="middle_name"
                      v-model="filler_json.middle_name"
                      outlined
                      dense
                      hide-details="auto"
                      background-color="white"
                    />
                  </label>
                </v-col>
                <v-col
                  v-if="!contacts.length"
                  class="d-flex align-end justify-end mb-1"
                  :offset="2"
                >
                  <v-btn
                    dense
                    class="elevation-0"
                    @click="addContactItem"
                  >
                    <v-icon>mdi-plus</v-icon>
                    Контакт
                  </v-btn>
                </v-col>
                <v-col
                  v-if="contacts.length"
                  ref="contacts"
                  cols="4"
                  offset="2"
                  class="mb-2"
                >
                  <single-contact
                    v-for="(contact, index) in contacts"
                    :key="index"
                    class="mr-0"
                    :types="contact_types"
                    :item="contact"
                    :idx="index"
                    :contacts-length="contacts.length"
                    @deleteContactItem="deleteContactItem"
                    @addContactItem="addContactItem"
                    @contactsUpdater="contactsUpdater"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="mb-2"
                >
                  <div class="font-weight-bold d-flex align-center mt-2">
                    Документ, удостоверяющий полномочия
                  </div>
                  <v-row>
                    <v-col cols="4">
                      <label>
                        Номер документа
                        <v-text-field
                          ref="auth_doc_number"
                          v-model="filler_json.auth_doc_number"
                          outlined
                          dense
                          hide-details="auto"
                          background-color="white"
                        />
                      </label>
                    </v-col>
                    <v-col cols="3">
                      <label>
                        Дата действия с
                        <custom-date-picker
                          ref="auth_doc_d_on"
                          v-model="filler_json.auth_doc_d_on"
                          :visited.sync="fields_been_visited"
                          :has-changes.sync="hasChanges"
                        />
                      </label>
                    </v-col>
                    <v-col cols="3">
                      <label>
                        Дата действия по
                        <custom-date-picker
                          ref="auth_doc_d_off"
                          v-model="filler_json.auth_doc_d_off"
                          :visited.sync="fields_been_visited"
                          :has-changes.sync="hasChanges"
                        />
                      </label>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                  cols="6"
                  style="border: 1px solid black"
                  class="rounded mb-2"
                >
                  <div class="font-weight-bold d-flex align-center mt-2">
                    {{ isOldStructure ? 'Таможенное агентство' : '9 Таможенный представитель' }}
                  </div>
                  <v-row>
                    <v-col cols="3">
                      <label>
                        Реестр
                        <v-text-field
                          v-if="isOldStructure"
                          ref="customs_agent_license_num"
                          v-model="filler_json.customs_agent_license_num"
                          outlined
                          dense
                          hide-details="auto"
                          background-color="white"
                        />
                        <v-autocomplete
                          v-else
                          ref="customs_agent_license_num"
                          v-model="filler_json.customs_agent_license_num"
                          :items="agents"
                          item-text="text"
                          item-value="licenseNumber"
                          outlined
                          dense
                          hide-details="auto"
                          background-color="white"
                        />
                      </label>
                    </v-col>
                    <v-col cols="3">
                      <label>
                        Договор
                        <v-text-field
                          ref="customs_agent_contract_num"
                          v-model="filler_json.customs_agent_contract_num"
                          outlined
                          dense
                          hide-details="auto"
                          background-color="white"
                        />
                      </label>
                    </v-col>
                    <v-col cols="3">
                      <label>
                        Дата договора
                        <custom-date-picker
                          ref="customs_agent_contract_date"
                          v-model="filler_json.customs_agent_contract_date"
                        />
                      </label>
                    </v-col>
                    <v-col
                      cols="3"
                    >
                      <label>
                        Аттестат ТП
                        <v-text-field
                          ref="certificate_number"
                          v-model="filler_json.certificate_number"
                          outlined
                          dense
                          hide-details="auto"
                          background-color="white"
                        />
                      </label>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <component
      :is="activeComponent"
      :id="selectedId"
      module="epi"
      :root-user-id="rootUserId"
      :show.sync="showComponent"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import CustomDatePicker from "@/components/shared/custom-date-picker.vue";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import {
  getCountryNameByCode,
  getIdentityCodeByName,
} from "@/helpers/catalogs";
import { getInitialsString } from "@/helpers/text";
import moment from "moment";
import cloneDeep from "lodash.clonedeep";
import SingleContact from "@/components/epi/form/single-contact.vue";
import { convertEmptyStringsToNull } from "@/helpers/objects";
import { filler as onFiller } from "@/events/statistics/control";
import { highlightField } from "@/helpers/control";
import blockVisibility from '@/mixins/block-visibility'
import {checkContacts} from "@/components/epi/form/regexp";

export default {
  components: { SingleContact, CustomDatePicker },
  mixins: [filterBySearchFieldMixin, blockAutoUpdate, blockVisibility],
  data: () => ({
    block_id: "epi-filler",
    rootUserId:null,
    filler: {
      declaration_id: null,
    },
    filler_json: {
      last_name: "",
      first_name: "",
      middle_name: "",
      position: "",
      country_letter: "",
      country_name: "",
      identity_doc_code: "",
      identity_doc_name: "",
      identity_doc_number: "",
      identity_doc_d_on: "",
      auth_doc_number: "",
      auth_doc_d_on: "",
      identity_doc_d_off: "",
      auth_doc_d_off: "",
      certificate_number: "",
      customs_agent_contract_num: "",
      customs_agent_contract_date: "",
      customs_agent_license_num: "",
    },
    contacts: [],
    showComponent:false,
    activeComponent:null,
    actions:[
      {
        action:"changePerformer",
        component:() => import('@/components/documents/change-performer.vue')
      }
    ]
  }),
  computed: {
    ...mapGetters({
      selected: "epi/getSelected",
      countries: "catalogs/getNsiCountries",
      identityDocuments: "catalogs/getNsiIdentityDocuments",
      user: "auth/getUser",
      contact_types: "catalogs/getContactTypes",
      isOldStructure: "epi/getIsOldStructure",
      agents: "catalogs/getNsiCustomsAgent",
    }),
    allowChangePerformer(){
      return this.selected.user_id === this.userId ? true : this.$can('doc.div.user.update')
    },
    userId(){
      return this.user?.user?.id
    },
    selectedId(){
      return this.selected.id
    },
    fillerName() {
      return getInitialsString(this.filler_json);
    },
    fillerDocDate() {
      return moment(this.filler_json.identity_doc_d_on).isValid()
        ? moment(this.filler_json.identity_doc_d_on).format("DD.MM.YYYY")
        : "";
    },
    identityDocumentsFiltered() {
      if (this.filler_json.country_letter) {
        if (
          ["BY", "RU", "KZ", "KG", "AM"].includes(
            this.filler_json.country_letter
          )
        ) {
          return this.identityDocuments.filter((item) => {
            return item.code.startsWith(this.filler_json.country_letter);
          });
        }
        return this.identityDocuments.filter((item) => {
          return item.code.startsWith("XX");
        });
      }
      return this.identityDocuments;
    },
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    },
  },
  created() {
    onFiller.subscribe(this.prepareView);
  },
  beforeDestroy() {
    onFiller.unsubscribe();
  },
  methods: {
    highlightField,
    changePerformer(){
      const action = this.actions.find(i => i.action === 'changePerformer')
      this.activeComponent = action.component || null
      this.showComponent = true
    },
    prepareView(data){
      this.collapsed ? this.collapsed = false : false;
      this.$nextTick(() => {
        this.highlightField(data)
      })
    },
    setFields() {
      this.rootUserId = this.selected.user_id
      const { declaration_id, filler_json } = this.selected?.filler || {};
      this.filler.declaration_id = declaration_id;
      Object.keys(this.filler_json).forEach((key) => {
        if (key in filler_json) {
          this.filler_json[key] = filler_json[key];
        }
      });
      const contacts = filler_json?.contacts ?? [];
      this.contacts = cloneDeep(contacts);
    },
    getContactsWithName() {
      return this.contacts.map((item) => {
        const { name } =
          this.contact_types.find((i) => i.code === item.code) ?? {};
        return {
          ...item,
          name,
        };
      });
    },
    readyToUpdate() {
      this.hasChanges = true;
      this.fields_been_visited = true;
    },
    addContactItem(type) {
      const contact = {
        code: "",
        name: "",
        number: "",
      }
      if (type === 'branch') {
        this.branch_contacts.push(contact);
      } else {
        this.contacts.push(contact);
      }
      this.readyToUpdate();
    },
    deleteContactItem(index, type) {
      if (type === 'branch') {
        this.branch_contacts.splice(index, 1)
      } else {
        this.contacts.splice(index, 1);
      }
      this.readyToUpdate();
    },
    contactsUpdater(payload) {
      this.contacts[payload.index] = payload.cont_item;
      this.readyToUpdate();
    },
    fillFromUser() {
      this.$store
        .dispatch("auth/getUserPersonalById", this.user.user.id)
        .then((res) => {
          const user = res.data;
          const docCode = user.user_doc_code?.toString()
          const isPassport = !(docCode?.startsWith('XX'))
          const country_letter = isPassport ? docCode.slice(0, 2).toUpperCase() : "";
          const identity = this.identityDocuments.find(
            (i) => i.code === user.user_doc_code
          )
          this.filler_json = {
            last_name: user.last_name,
            first_name: user.first_name,
            middle_name: user.middle_name,
            position: user.uid_user_position,
            country_name: "",
            identity_doc_number: [
              user.user_doc_series,
              user.user_doc_number,
            ].join(""),
            identity_doc_d_on: user.user_doc_d_on,
            identity_doc_d_off: null,
            auth_doc_number: user.uid_doc_number,
            auth_doc_d_on: user.uid_doc_d_on,
            auth_doc_d_off: user.uid_doc_d_off,
            certificate_number: user.user_certificate,
            customs_agent_contract_num: "",
            customs_agent_contract_date: "",
            customs_agent_license_num: "",
            identity_doc_code: identity?.code,
            identity_doc_name: identity?.name,
            country_letter,
          };
          this.contacts = [
            {
              code: "TE",
              name: "ТЕЛЕФОН",
              number: user.uid_phone,
            },
          ];
          this.fieldsBeenVisitedByUser();
          this.triggerOnChange();
        })
        .catch(() => {
          this.$snackbar({
            text: "Ошибка получения данных",
            color: "red",
            top: false,
          });
        });
    },
    async uploadData() {
      checkContacts.call(this, this.contacts)
      const filler = convertEmptyStringsToNull({
        ...this.filler,
        filler_json: {
          ...this.filler_json,
          identity_doc_d_off: null,
          country_name:
            getCountryNameByCode(
              this.countries,
              this.filler_json.country_letter
            ) || null,
          contacts: this.getContactsWithName(),
          identity_doc_code:
            getIdentityCodeByName(
              this.identityDocuments,
              this.filler_json.identity_doc_name
            ) || null,
        },
      });
      return this.$store.dispatch("epi/uploadBlockData", {
        name: "filler",
        value: filler,
      });
    },
  },
};
</script>
